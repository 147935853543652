import React, { Fragment, useEffect, useState } from "react";
import { animated, useChain, useSpring, useSpringRef } from "react-spring";
import styles from "../projects/projects.module.scss";
import Gallery from "./gallery";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Options, Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { storage } from "../../firebase";
import { getDownloadURL, listAll, ref } from "firebase/storage";
import TestGallery from "./test-gallery";
import axios from "axios";

const Projects = () => {
  const projectsGallery: {
    id: number;
    title: string;
    imgSrc: string;
    name: string;
  }[] = [
    {
      id: 0,
      title: "Apartmány Bešeňová",
      imgSrc: require("../../assets/img/gino.jpg"),
      name: "gino-paradise",
    },
    {
      id: 1,
      title: "Rezidencia Prestige - Malé Tatry",
      imgSrc: require("../../assets/img/PrestigeVizualizationImg.jpg"),
      name: "male-tatry",
    },
    {
      id: 2,
      title: "Rezidencia Galéria",
      imgSrc: require("../../assets/img/RezidenciaGaleria.jpg"),
      name: "galeria-prestige",
    },
    {
      id: 3,
      title: "Kostol Beňadovo",
      imgSrc:
        "https://firebasestorage.googleapis.com/v0/b/mp-therm.appspot.com/o/benadovo-kostol%2FIMG_4804.jpeg?alt=media&token=ef9ca19a-1b48-486b-88bb-3145180daff0",
      name: "benadovo-kostol",
    },
    {
      id: 4,
      title: "Hrabovo chata",
      imgSrc:
        "https://firebasestorage.googleapis.com/v0/b/mp-therm.appspot.com/o/hrabovo-chata%2FIMG_%2016.jpg?alt=media&token=0660052d-92a9-46da-adfa-4d4f81852267",
      name: "hrabovo-chata",
    },
    {
      id: 5,
      title: "Bytovka Lipovské Revúce",
      imgSrc:
        "https://firebasestorage.googleapis.com/v0/b/mp-therm.appspot.com/o/bytovka-lip-revuce%2FIMG_06.jpg?alt=media&token=99422e76-927a-4d25-a269-b7557340c05c",
      name: "bytovka-lip-revuce",
    },
    {
      id: 6,
      title: "Martin balkóny",
      imgSrc:
        "https://firebasestorage.googleapis.com/v0/b/mp-therm.appspot.com/o/martin-balkony%2FIMG_10.jpg?alt=media&token=55803a6d-e357-43a2-b03b-83c7ffd08fe0",
      name: "martin-balkony",
    },
    {
      id: 7,
      title: "Rodinný dom Nolcovo",
      imgSrc:
        "https://firebasestorage.googleapis.com/v0/b/mp-therm.appspot.com/o/nolcovo-dom%2FIMG_11.jpeg?alt=media&token=98a1ba73-0e9a-4c2a-9df3-3d0c6c1e0060",
      name: "nolcovo-dom",
    },
    {
      id: 8,
      title: "Orion Ružomberok",
      imgSrc:
        "https://firebasestorage.googleapis.com/v0/b/mp-therm.appspot.com/o/orion%2FIMG_14.jpg?alt=media&token=2c80e44c-34e5-4848-9ad9-60e4493beded",
      name: "orion",
    },
    {
      id: 9,
      title: "Rodinný dom Štiavnička",
      imgSrc:
        "https://firebasestorage.googleapis.com/v0/b/mp-therm.appspot.com/o/stiavnicka-dom%2FIMG_19.jpeg?alt=media&token=8f62503d-8464-4df8-8042-9a777395fc25",
      name: "stiavnicka-dom",
    },
  ];

  const [startAnimation, setStartAnimation] = useState<boolean>(true);
  const [isGalleryVisible, setIsGalleryVisible] = useState<boolean>(false);
  const [images, setImages] = useState<string[]>([]);

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler);

    // const response = axios
    //   .get(`https://api.cloudinary.com/v1_1/dyjprg5p9/resources/image`, {
    //     params: {
    //       type: "upload",
    //       prefix: "MP Therm/Benadovo kostol",
    //       api_key: "759385336212318", // Replace with your Cloudinary API key
    //       api_secret: "k5NjThG3SdNoFBnhBCOvyWCdp3E", // Replace with your Cloudinary API secret
    //     },
    //   })
    //   .then((result) => {
    //     console.log(result);
    //   });

    return () => window.removeEventListener("scroll", scrollHandler);
  }, []);

  const getProjectImages = async (projectUrlName: string) => {
    if (projectUrlName === "") {
      return [];
    }
    const folderRef = ref(
      storage,
      `gs://mp-therm.appspot.com/${projectUrlName}`
    );

    try {
      const images = await listAll(folderRef);

      const imagePromises = images.items.map(async (imageRef) => {
        const downloadUrl = await getDownloadURL(imageRef);
        return downloadUrl;
      });

      const imageUrls = await Promise.all(imagePromises);
      return imageUrls;
    } catch (error) {
      console.log("Error fetching images:", error);
      return [];
    }
  };

  const scrollHandler = () => {
    const projectsSection = document.getElementById("section-projects");
    if (projectsSection != null) {
      const actualScrollPosition = window.scrollY + window.innerHeight;
      const startPoint = projectsSection.offsetTop + 300; //projectsSection.offsetHeight / 1.6;

      // console.log(actualScrollPosition, startPoint);
      if (actualScrollPosition > startPoint) {
        setStartAnimation(true);
      } else if (actualScrollPosition <= startPoint) {
        setStartAnimation(false);
      }
    }
  };

  const openGallery = (projectName: string) => {
    getProjectImages(projectName)
      .then((urls) => {
        setImages(urls);
        setIsGalleryVisible(true);
      })
      .catch((error) => console.log(error));
  };

  //** ANIMATIONS */
  const [startFadeOutAnimation, setStartFadeOutAnimation] =
    useState<boolean>(false);

  const fadeOutAnimation = useSpring({
    from: {
      transform: startFadeOutAnimation
        ? "translateY(0), opacity: 1"
        : "translateY(50%), opacity: 0",
    },
    to: { transform: "translateY(50%), opacity: 0" },
    config: { duration: 500 },
  });

  return (
    <div className={styles.projects} id="section-projects">
      <div
        className={`${styles.titles} ${
          startAnimation ? styles["title-fadein"] : ""
        }`}
      >
        <span></span>
        <p>Naša práca</p>
      </div>

      {isGalleryVisible && (
        <TestGallery
          images={images}
          isGalleryOpen={isGalleryVisible}
          closeGallery={() => setIsGalleryVisible(false)}
        />
      )}
      {/*       
      {isGalleryVisible ? (
        <Fragment>
          <div className={`${styles.titles} ${styles["title-fadein"]}`}>
            <span></span>
            <p>Galéria</p>
          </div>
          <Gallery
            isGalleryOpen={isGalleryVisible}
            closeGallery={() => {
              setIsGalleryVisible(false);
              setStartAnimation(true);
              setStartFadeOutAnimation(false);
            }}
          />
        </Fragment>
      ) : ( */}
      <animated.div
        style={fadeOutAnimation}
        className={`${styles["projects__gallery"]}`}
      >
        <div className={`${styles["splide"]}`}>
          <Splide
            options={{
              perPage: 3,
              perMove: 1,
              pagination: true,
              breakpoints: {
                800: {
                  perPage: 2,
                },
                600: {
                  perPage: 1,
                },
              },
            }}
          >
            {projectsGallery.map((item, index) => (
              <SplideSlide>
                <div
                  className={`${styles["projects__gallery--image"]} ${
                    startAnimation
                      ? styles["element_1_slide_effect"]
                      : styles.hide
                  }`}
                >
                  <img src={item.imgSrc} />
                  <div className={`${styles["image__filter"]}`}></div>
                  <div className={`${styles["image__info"]}`}>
                    <span></span>
                    <p className={`${styles["image__info--text"]}`}>
                      {item.title}
                    </p>
                  </div>
                  <div
                    className={`${styles["image__gallery"]}`}
                    onClick={() => openGallery(item.name)}
                  >
                    <p>Zobraziť galériu</p>
                  </div>
                </div>
              </SplideSlide>
            ))}
          </Splide>
        </div>
      </animated.div>
      {/* )} */}
    </div>
  );
};

export default Projects;

import React, { useEffect, useRef, useState } from "react";
import styles from "../contact/contact.module.scss";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const [startAnimation, setStartAnimation] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, []);

  const scrollHandler = () => {
    const servicesSection = document.getElementById("section-services");
    if (servicesSection != null) {
      const actualScrollPosition = window.scrollY + window.innerHeight;
      const startPoint =
        servicesSection.offsetTop + servicesSection.offsetHeight / 2;

      if (actualScrollPosition > startPoint) {
        setStartAnimation(true);
      } else if (actualScrollPosition <= startPoint) {
        setStartAnimation(false);
      }
    }
  };

  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    console.log("send email....");

    emailjs
      .sendForm(
        "service_txsur92",
        "template_xf2broh",
        e.currentTarget,
        "ztDbCJbqiM5GyzZn_"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className={`${styles["contact-section"]}`} id="section-contact">
      <div className={`${styles["contact-section__shadow"]}`}></div>

      <div className={`${styles["contact-wrapper"]}`}>
        <h2 className={`${styles["contact-wrapper__title"]}`}>
          Kontaktujte nás
        </h2>

        <div className={styles.contact}>
          <div className={`${styles["contact__info"]}`}>
            <div className={`${styles["contact__info--wrapper"]}`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="192"
                height="192"
                fill="#f03e3e"
                viewBox="0 0 256 256"
              >
                <rect width="256" height="256" fill="none"></rect>
                <circle
                  cx="128"
                  cy="104"
                  r="32"
                  fill="none"
                  stroke="#f03e3e"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="16"
                ></circle>
                <path
                  d="M208,104c0,72-80,128-80,128S48,176,48,104a80,80,0,0,1,160,0Z"
                  fill="none"
                  stroke="#f03e3e"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="16"
                ></path>
              </svg>

              <div className={`${styles["contact-details"]}`}>
                <p>Adresa</p>
                <p>Liesek 617, 027 12 Liesek</p>
              </div>
            </div>
            <div className={`${styles["contact__info--wrapper"]}`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="192"
                height="192"
                fill="#f03e3e"
                viewBox="0 0 256 256"
              >
                <rect width="256" height="256" fill="none"></rect>
                <polyline
                  points="224 56 128 144 32 56"
                  fill="none"
                  stroke="#f03e3e"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="16"
                ></polyline>
                <path
                  d="M32,56H224a0,0,0,0,1,0,0V192a8,8,0,0,1-8,8H40a8,8,0,0,1-8-8V56A0,0,0,0,1,32,56Z"
                  fill="none"
                  stroke="#f03e3e"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="16"
                ></path>
                <line
                  x1="110.5"
                  y1="128"
                  x2="34.5"
                  y2="197.7"
                  fill="none"
                  stroke="#f03e3e"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="16"
                ></line>
                <line
                  x1="221.5"
                  y1="197.7"
                  x2="145.5"
                  y2="128"
                  fill="none"
                  stroke="#f03e3e"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="16"
                ></line>
              </svg>
              <div className={`${styles["contact-details"]}`}>
                <p>Email</p>
                <p>polacek.mptherm@gmail.com</p>
              </div>
            </div>
            <div className={`${styles["contact__info--wrapper"]}`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="192"
                height="192"
                fill="#f03e3e"
                viewBox="0 0 256 256"
              >
                <rect width="256" height="256" fill="none"></rect>
                <path
                  d="M92.5,124.8a83.6,83.6,0,0,0,39,38.9,8,8,0,0,0,7.9-.6l25-16.7a7.9,7.9,0,0,1,7.6-.7l46.8,20.1a7.9,7.9,0,0,1,4.8,8.3A48,48,0,0,1,176,216,136,136,0,0,1,40,80,48,48,0,0,1,81.9,32.4a7.9,7.9,0,0,1,8.3,4.8l20.1,46.9a8,8,0,0,1-.6,7.5L93,117A8,8,0,0,0,92.5,124.8Z"
                  fill="none"
                  stroke="#f03e3e"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="16"
                ></path>
              </svg>
              <div className={`${styles["contact-details"]}`}>
                <p>Zavolajte nám</p>
                <p>+421 933 987 653</p>
              </div>
            </div>
            <div
              className={`${styles["contact__info--wrapper"]} ${styles["contact__instagram"]}`}
              onClick={() =>
                window.open("https://www.instagram.com/mp__therm/", "_blank")
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="52"
                height="52"
                fill="#f03e3e"
                viewBox="0 0 256 256"
              >
                <path d="M128,80a48,48,0,1,0,48,48A48.05,48.05,0,0,0,128,80Zm0,80a32,32,0,1,1,32-32A32,32,0,0,1,128,160ZM176,24H80A56.06,56.06,0,0,0,24,80v96a56.06,56.06,0,0,0,56,56h96a56.06,56.06,0,0,0,56-56V80A56.06,56.06,0,0,0,176,24Zm40,152a40,40,0,0,1-40,40H80a40,40,0,0,1-40-40V80A40,40,0,0,1,80,40h96a40,40,0,0,1,40,40ZM192,76a12,12,0,1,1-12-12A12,12,0,0,1,192,76Z"></path>
              </svg>
              <div className={`${styles["contact-details"]}`}>
                <p>Instagram</p>
                <p>mp__therm</p>
              </div>
            </div>
          </div>
          <div>
            <form onSubmit={sendEmail} className={`${styles["contact__form"]}`}>
              <input
                name="contactName"
                className={`${styles["contact__form--input"]}`}
                type="text"
                placeholder="Vaše meno"
              />
              <input
                name="contactEmail"
                className={`${styles["contact__form--input"]}`}
                type="text"
                placeholder="Váš email"
              />
              <input
                name="subject"
                className={`${styles["contact__form--input"]}`}
                type="text"
                placeholder="Predmet správy"
              />
              <textarea
                name="message"
                className={`${styles["contact__form--area"]}`}
                placeholder="Správa"
              ></textarea>
              <button
                className={`${styles["contact__form--button"]}`}
                type="submit"
              >
                Odoslať
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;

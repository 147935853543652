import React, { useCallback, useEffect, useState } from "react";
import { ActualProject } from "../../models/actualProject";
import styles from "../actual-projects/actualProjects.module.scss";

const ActualProjects = () => {
  const actualProjects: ActualProject[] = [
    {
      image1: require("../../assets/img/actualProjects/OrionVizualizationImg.jpg"),
      image2: require("../../assets/img/actualProjects/OrionRealImg.jpeg"),
      title: "Orion Ružomberok",
      description: [
        "150 bytov",
        "prestavba niekdajšieho hotelového komplexu IKAR",
      ],
      webUrl: "https://www.bytyorion.sk/",
    },
    // {
    //   image1: require("../../assets/img/PrestigeVizualizationImg.jpg"),
    //   image2: require("../../assets/img/actualProjects/PrestigeRealImg.jpeg"),
    //   title: "Prestige Malé Tatry",
    //   description: [
    //     "4-poschodový bytový dom s 21 bytovými jednotkami",
    //     "tretia etapa výstavby v tejto exkluzívnej lokalite",
    //   ],
    //   webUrl: "https://www.maletatry.sk/",
    // },
  ];

  const [startAnimation, setStartAnimation] = useState<boolean>(true);
  const [selectedProjectIndex, setSelectedProjectIndex] = useState<number>(0);
  const [isMobileVersion, setIsMobileVersion] = useState<boolean>(false);
  const [selectedProjectPhotoIndex, setSelectedProjectPhotoIndex] =
    useState<number>(0);

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth <= 600; // or any other value for mobile
      setIsMobileVersion(isMobile);
    };

    handleResize(); // call the function initially
    window.addEventListener("resize", handleResize); // add event listener for resize

    return () => window.removeEventListener("resize", handleResize); // clean up on unmount
  }, []);

  const scrollHandler = () => {
    const actualProjectsSection = document.getElementById(
      "section-actual-projects"
    );
    if (actualProjectsSection != null) {
      const actualScrollPosition = window.scrollY + window.innerHeight;
      const startPoint = actualProjectsSection.offsetTop + 300; //aboutSection.offsetHeight / 1.6;

      if (actualScrollPosition > startPoint) {
        setStartAnimation(true);
      } else if (actualScrollPosition <= startPoint) {
        setStartAnimation(false);
      }
    }
  };

  const nextProject = () => {
    if (selectedProjectIndex === actualProjects.length - 1) {
      setSelectedProjectIndex(0);
    } else {
      setSelectedProjectIndex((prevValue) => prevValue + 1);
    }
  };

  const [touchPosition, setTouchPosition] = useState(null);
  // ...
  const handleTouchStart = (e: any) => {
    const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown);
  };

  const handleTouchMove = (e: any, isProject: boolean) => {
    const touchDown = touchPosition;

    if (touchDown === null) {
      return;
    }

    const currentTouch = e.touches[0].clientX;
    const diff = touchDown - currentTouch;

    if (diff > 10) {
      if (isProject) {
        if (selectedProjectIndex === actualProjects.length - 1) {
          setSelectedProjectIndex(0);
        } else {
          setSelectedProjectIndex((prevValue) => prevValue + 1);
        }
      } else {
        if (selectedProjectPhotoIndex === 1) {
          setSelectedProjectPhotoIndex(0);
        } else {
          setSelectedProjectPhotoIndex(1);
        }
      }
    }

    if (diff < -10) {
      if (isProject) {
        if (selectedProjectIndex === 0) {
          setSelectedProjectIndex(actualProjects.length - 1);
        } else {
          setSelectedProjectIndex((prevValue) => prevValue - 1);
        }
      } else {
        if (selectedProjectPhotoIndex === 0) {
          setSelectedProjectPhotoIndex(1);
        } else {
          setSelectedProjectPhotoIndex(0);
        }
      }
    }

    setTouchPosition(null);
  };

  return (
    <div
      id="section-actual-projects"
      className={styles["section-actual-projects"]}
    >
      <div
        className={`${styles.titles} ${
          startAnimation ? styles["title-fadein"] : ""
        }`}
        id="about-tittle"
      >
        <span></span>
        <p>Aktuálne projekty</p>
      </div>

      {actualProjects.map((project, index) => (
        <div
          className={`${
            selectedProjectIndex === index
              ? styles["project"]
              : styles["hide-project"]
          }`}
          key={index}
        >
          <div
            className={`${styles["project__images"]}`}
            onTouchStart={handleTouchStart}
            onTouchMove={(e) => handleTouchMove(e, false)}
          >
            <img
              className={`${styles["project__images--img1"]} ${
                isMobileVersion &&
                selectedProjectPhotoIndex !== 0 &&
                styles["hide-project"]
              }`}
              src={project.image1}
            />
            <img
              className={`${styles["project__images--img2"]} ${
                isMobileVersion &&
                selectedProjectPhotoIndex !== 1 &&
                styles["hide-project"]
              }`}
              src={project.image2}
            />
            {isMobileVersion && (
              <ul className={`${styles["dots"]} ${styles["photo-dots"]}`}>
                <li
                  className={`${
                    selectedProjectPhotoIndex === 0 ? styles["dot-active"] : ""
                  }`}
                ></li>
                <li
                  className={`${
                    selectedProjectPhotoIndex === 1 ? styles["dot-active"] : ""
                  }`}
                ></li>
              </ul>
            )}
          </div>

          <div
            className={`${styles["project__info"]}`}
            onTouchStart={handleTouchStart}
            onTouchMove={(e) => handleTouchMove(e, true)}
          >
            <p className={styles["project__info--title"]}>{project.title}</p>
            <ul className={styles["project__info--description"]}>
              {project.description.map((infoText, i) => (
                <li key={i}>{infoText}</li>
              ))}
            </ul>

            <a
              className={styles["project__info--button"]}
              href={project.webUrl}
            >
              Zistiť viac
            </a>
          </div>
        </div>
      ))}

      <ul className={styles["dots"]}>
        {actualProjects.map((project, index) => (
          <li
            key={index}
            onClick={() => setSelectedProjectIndex(index)}
            className={`${
              selectedProjectIndex === index ? styles["dot-active"] : ""
            }`}
          ></li>
        ))}
      </ul>
    </div>
  );
};

export default ActualProjects;

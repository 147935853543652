import React, { useEffect, useState } from "react";
import styles from "../../introduction/menu/menu.module.scss";

interface IProps {
  scrollToSection: string;
}

const Menu: React.FC<IProps> = (props) => {
  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false);
  const [isMenuFixed, setIsMenuFixed] = useState<boolean>(false);
  const [selectedSection, setSelectedSection] = useState<string>(
    "section-introduction"
  );
  const sections: string[] = [
    "section-introduction",
    "section-about",
    "section-projects",
    "section-actual-projects",
    "section-services",
    "section-contact",
  ];

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, []);

  useEffect(() => {
    if (props.scrollToSection !== "") {
      scrollToPosition(props.scrollToSection);
    }
  }, [props.scrollToSection]);

  const scrollToPosition = (elementId: string) => {
    const sectionToScroll = document.getElementById(elementId);
    if (sectionToScroll != null) {
      setSelectedSection(elementId);
      setIsMenuVisible(false);
      let difference = 0;

      if (window.innerHeight > sectionToScroll.scrollHeight) {
        difference = window.innerHeight - sectionToScroll.scrollHeight;
      }
      window.scrollTo(0, sectionToScroll.offsetTop - difference);
    }
  };

  const scrollHandler = () => {
    const aboutSection = document.getElementById("section-about");
    if (aboutSection != null) {
      const breakPoint =
        aboutSection.scrollHeight * 0.6 + aboutSection.offsetTop;
      const bottomPosition = window.scrollY + window.innerHeight;

      if (
        aboutSection.offsetTop <= window.scrollY ||
        bottomPosition >= breakPoint
      ) {
        setIsMenuFixed(true);
      } else {
        setIsMenuFixed(false);
      }
    }

    sections.forEach((element) => {
      if (changeSelectedSection(element)) {
        setSelectedSection(element);
      }
    });
  };

  const changeSelectedSection = (sectionId: string): boolean => {
    const section = document.getElementById(sectionId);
    if (section != null) {
      const difference =
        section.offsetTop - (window.innerHeight - section.scrollHeight / 2);
      if (difference <= window.scrollY) {
        return true;
      }
    }
    return false;
  };

  return (
    <div
      className={`${styles.menu} ${
        isMenuFixed ? styles["fix__menu"] : styles["normal__menu"]
      }`}
    >
      <div>
        <a
          className={`${styles["menu__logo"]}`}
          href="#"
          onClick={() => {
            scrollToPosition("section-introduction");
          }}
        >
          <img src={require("../../../assets/img/logo.png")} />
          <span className={styles["menu__logo--title"]}>MP-Therm s.r.o.</span>
        </a>
      </div>

      <ul
        className={`${styles["menu__items"]} ${
          isMenuVisible ? styles["menu__visible"] : styles["menu__hide"]
        }`}
      >
        <li
          className={`${styles.item} ${
            selectedSection === "section-introduction"
              ? styles["active__item"]
              : ""
          }`}
        >
          <a
            href="#"
            onClick={() => {
              scrollToPosition("section-introduction");
            }}
          >
            Úvod
          </a>
        </li>
        <li
          className={`${styles.item} ${
            selectedSection === "section-about" ? styles["active__item"] : ""
          }`}
        >
          <a onClick={() => scrollToPosition("section-about")}>O nás</a>
        </li>
        <li
          className={`${styles.item} ${
            selectedSection === "section-projects" ? styles["active__item"] : ""
          }`}
        >
          <a onClick={() => scrollToPosition("section-projects")}>Galéria</a>
        </li>
        <li
          className={`${styles.item} ${
            selectedSection === "section-actual-projects"
              ? styles["active__item"]
              : ""
          }`}
        >
          <a onClick={() => scrollToPosition("section-actual-projects")}>
            Aktuálne projekty
          </a>
        </li>
        <li
          className={`${styles.item} ${
            selectedSection === "section-services" ? styles["active__item"] : ""
          }`}
        >
          <a onClick={() => scrollToPosition("section-services")}>Služby</a>
        </li>
        <li
          className={`${styles.item} ${
            selectedSection === "section-contact" ? styles["active__item"] : ""
          }`}
        >
          <a onClick={() => scrollToPosition("section-contact")}>Kontakt</a>
        </li>
      </ul>
      <div className={styles["menu__button"]}>
        <svg
          onClick={() => setIsMenuVisible(true)}
          xmlns="http://www.w3.org/2000/svg"
          width="192"
          height="192"
          fill="#f03e3e"
          viewBox="0 0 256 256"
        >
          <rect width="256" height="256" fill="none"></rect>
          <line
            x1="40"
            y1="128"
            x2="216"
            y2="128"
            stroke="#f03e3e"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="16"
          ></line>
          <line
            x1="40"
            y1="64"
            x2="216"
            y2="64"
            stroke="#f03e3e"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="16"
          ></line>
          <line
            x1="40"
            y1="192"
            x2="216"
            y2="192"
            stroke="#f03e3e"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="16"
          ></line>
        </svg>
      </div>
    </div>
  );
};

export default Menu;

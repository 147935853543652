import React, { useEffect, useState } from "react";
import styles from "../projects/gallery.module.scss";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";

interface IProps {
  images: string[];
  isGalleryOpen: boolean;
  closeGallery: () => void;
}

const TestGallery: React.FC<IProps> = (props) => {
  const [isGalleryOpen, setIsGalleryOpen] = useState<boolean>(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);

  return (
    <div className={`${styles["selected-project"]}`}>
      <div
        className={`${styles["selected-project__close-icon"]}`}
        onClick={() => props.closeGallery()}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </div>

      <div
        className={`${styles["selected-project__selected-image"]} 
          ${selectedImageIndex >= 0 ? styles.fade : ""}`}
      >
        {/* <div className={styles["arrow"]}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15.75 19.5L8.25 12l7.5-7.5"
            />
          </svg>
        </div> */}
        <img src={props.images[selectedImageIndex]} />
        {/* <div className={styles["arrow"]}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M8.25 4.5l7.5 7.5-7.5 7.5"
            />
          </svg>
        </div> */}
      </div>
      <div className={`${styles["selected-project__images"]}`}>
        <Splide
          options={{
            // type: "slide",
            rewind: true,
            perPage: 8,
            perMove: 1,
            gap: "1rem",
            pagination: false,
            arrows: true,
            isNavigation: true,
            focus: "center",
            fixedHeight: "10rem",
            breakpoints: {
              1200: {
                fixedHeight: "15rem",
              },
              1000: {
                perPage: 5,
              },
            },
          }}
          onActive={(splide) => setSelectedImageIndex(splide.index)}
        >
          {props.images.map((image, i) => (
            <SplideSlide>
              <img
                className={`${
                  i === selectedImageIndex && styles["active-image"]
                }`}
                key={i}
                src={image}
                onClick={() => setSelectedImageIndex(i)}
              />
            </SplideSlide>
          ))}
        </Splide>
      </div>
    </div>
  );
};

export default TestGallery;

import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyD7fHCxW19XmizEsogBS3h9cTIsecKJIkM",

  authDomain: "mp-therm.firebaseapp.com",

  projectId: "mp-therm",

  storageBucket: "mp-therm.appspot.com",

  messagingSenderId: "154513688099",

  appId: "1:154513688099:web:0529a20fe133a8e6c61396",
};

const app = initializeApp(firebaseConfig);

export const storage = getStorage(app);

import React, { useEffect, useRef, useState } from "react";
import { animated, useSpring } from "react-spring";
import { ServiceItem } from "../../models/serviceItem";
import styles from "../services/service.module.scss";

const Services = () => {
  let serviceItems: ServiceItem[] = [
    {
      id: 0,
      smallImage: require("../../assets/img/services/dlazba1.jpeg"),
      description: [
        "pokladanie dlažby, obkladu",
        "obkladanie domov, plotov z kameňa",
      ],
      title: "Obkladanie interiéru, exteriéru",
      images: [
        "https://firebasestorage.googleapis.com/v0/b/mp-therm.appspot.com/o/hrabovo-chata%2FIMG_%2007.jpeg?alt=media&token=bb26ab63-4ee6-4e90-a86f-24dff444d259",
        "https://firebasestorage.googleapis.com/v0/b/mp-therm.appspot.com/o/stiavnicka-dom%2FIMG_18.jpeg?alt=media&token=3e3767f3-71ea-4831-abe3-96a1db45a38a",
      ],
    },
    {
      id: 1,
      smallImage: require("../../assets/img/services/zateplenie-icon.jpg"),
      description: [
        "cenová ponuka",
        "kompletné zateplenie, izolácia rôznych typov budov",
        "dlhoročné skúsenosti, odborné rady",
      ],
      title: "Zateplenie rodinných, bytových domov",
      images: [
        "https://firebasestorage.googleapis.com/v0/b/mp-therm.appspot.com/o/nolcovo-dom%2FIMG_03.jpeg?alt=media&token=ea74379d-68b9-4a6f-ad52-f2d5f676e957",
        "https://firebasestorage.googleapis.com/v0/b/mp-therm.appspot.com/o/gino-paradise%2F14_20201113_142942.jpg?alt=media&token=a08f68a2-5a16-4fbe-b735-086d28fe0139",
      ],
    },
    // {
    //   id: 2,
    //   smallImage: require("../../assets/img/services/lesenie.jpg"),
    //   description: [
    //     "",
    //     "Fsamet consectetur, adipisicing elit.",
    //     "Lorem ipsum dolor sit",
    //   ],
    //   title: "Prenájom lešenia",
    //   images: [
    //     "https://firebasestorage.googleapis.com/v0/b/mp-therm.appspot.com/o/gino-paradise%2F01_20200224_150904.jpg?alt=media&token=69ba8106-f9d4-44ca-8f04-d90a55011a7e",
    //     require("../../assets/img/services/zateplenie2.jpg"),
    //   ],
    // },
  ];

  const [selectedService, setSelectedService] = useState<ServiceItem>(
    serviceItems[0]
  );

  const [startAnimation, setStartAnimation] = useState<string>("show");

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, []);

  const scrollHandler = () => {
    const servicesSection = document.getElementById("section-services");
    if (servicesSection != null) {
      const actualScrollPosition = window.scrollY + window.innerHeight;
      const startPoint = servicesSection.offsetTop + 400; //servicesSection.offsetHeight / 3;

      if (actualScrollPosition > startPoint) {
        setStartAnimation("show");
        animate();
      } else if (actualScrollPosition <= startPoint) {
        setStartAnimation("");
        resetAnimation();
      }
    }
  };

  const onNavLinkClick = (service: ServiceItem) => {
    if (service.id === selectedService.id) {
      return;
    }
    if (startAnimation !== "") {
      setStartAnimation("show");
    }
    setSelectedService(service);
  };

  // ANIMATION
  const element1 = useRef<HTMLDivElement>(null);
  const element2 = useRef<HTMLDivElement>(null);
  const element3 = useRef<HTMLDivElement>(null);

  const [animationElement1, setAnimationElement1] = useSpring(() => ({
    opacity: 0,
    transform: "translateY(50%)",
    easing: "ease-in-out",
    duration: 400,
  }));

  const [animationElement2, setAnimationElement2] = useSpring(() => ({
    opacity: 0,
    transform: "translateY(50%)",
    easing: "ease-in-out",
    duration: 700,
  }));

  const [animationElement3, setAnimationElement3] = useSpring(() => ({
    opacity: 0,
    transform: "translateY(50%)",
    easing: "ease-in-out",
    duration: 1000,
  }));

  const animate = () => {
    setAnimationElement1({
      opacity: 1,
      transform: "translateY(0)",
      easing: "ease-in-out",
      duration: 400,
    });
    setAnimationElement2({
      opacity: 1,
      transform: "translateY(0)",
      easing: "ease-in-out",
      duration: 700,
    });
    setAnimationElement3({
      opacity: 1,
      transform: "translateY(0)",
      easing: "ease-in-out",
      duration: 1000,
    });
  };

  const resetAnimation = () => {
    setAnimationElement1({
      opacity: 0,
      transform: "translateY(50%)",
      easing: "ease-in-out",
      duration: 400,
    });
    setAnimationElement2({
      opacity: 0,
      transform: "translateY(50%)",
      easing: "ease-in-out",
      duration: 700,
    });
    setAnimationElement3({
      opacity: 0,
      transform: "translateY(50%)",
      easing: "ease-in-out",
      duration: 1000,
    });
  };

  // const options = {
  //   threshold: 0.5,
  // };
  // const observer = new IntersectionObserver((entries) => {
  //   entries.forEach((entry) => {
  //     if (entry.isIntersecting) {
  //       if (entry.target === element3.current) {
  //         setAnimationElement1({
  //           from: { opacity: 0, transform: "translateY(50%)" },
  //           to: { opacity: 1, transform: "translateY(0)" },
  //           config: { duration: 400, easing: "ease-in-out" },
  //         });

  //       }
  //       observer.unobserve(entry.target);
  //     }
  //   });
  // }, options);

  // useEffect(() => {
  //   observer.observe(ref1.current);
  //   observer.observe(ref2.current);
  //   observer.observe(ref3.current);
  // }, [observer]);

  return (
    <div className={styles.services} id="section-services">
      <div
        className={`${styles.titles} ${styles["services__title"]}
      ${startAnimation === "show" ? styles["title-fadein"] : ""}`}
      >
        <span></span>
        <p>Služby</p>
      </div>

      {serviceItems.map((item, i) => (
        <div
          key={i}
          className={`${styles["services__item"]} ${
            selectedService.id === item.id ? styles.active : ""
          }`}
        >
          <animated.div
            style={animationElement1}
            className={`${styles["services__item--img1"]}`}
            ref={element1}
          >
            <img
              src={item.images[0]}
              alt=""
              // className={`${
              //   startAnimation === "show"
              //     ? styles["element_1_slide_effect"]
              //     : startAnimation === "hide"
              //     ? styles.hide
              //     : ""
              // }`}
            />
          </animated.div>

          <animated.div
            style={animationElement3}
            ref={element3}
            className={`${styles["services__item--info"]}`}
          >
            <img src={item.smallImage} alt="" />
            <h3>{selectedService.title}</h3>
            <ul>
              {item.description.map((description, x) => (
                <li key={x}>{description}</li>
              ))}
            </ul>
          </animated.div>

          <animated.div
            style={animationElement2}
            className={`${styles["services__item--img2"]}`}
            ref={element2}
          >
            <img
              src={item.images[1]}
              alt=""
              // className={`${
              //   startAnimation === "show"
              //     ? styles["element_2_slide_effect"]
              //     : startAnimation === "hide"
              //     ? styles.hide
              //     : ""
              // }`}
            />
          </animated.div>
        </div>
      ))}

      <ul
        className={`${styles["services__nav"]} ${
          startAnimation
            ? styles["element_3_slide_effect"]
            : startAnimation === "hide"
            ? styles.hide
            : ""
        }`}
      >
        {serviceItems.map((service, i) => (
          <li
            key={i}
            className={`${
              selectedService.id === service.id ? styles["li-active"] : ""
            }`}
            onClick={() => onNavLinkClick(service)}
          >
            {service.title}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Services;

import { useEffect, useState } from "react";
import { InformationItem } from "../../models/informationItem";
import styles from "../about/about.module.scss";

const AboutPage = () => {
  const aboutUsItems: InformationItem[] = [
    {
      title: "Dlhoročné skúsenosti",
      description:
        "V oblasti stavebníctva sa pohybujeme od roku 2015. Počas týchto rokov sme pracovali na mnohých rôznych projektoch.",
    },
    {
      title: "Práca na veľkých projektoch",
      description:
        "Počas uplynulých rokov sme sa stretli s malými projektmi ako je renovácia a zateplenie rodinných domov  až po veľké projekty ako sú napríklad 13. poschodové paneláky, priemyselné budovy, veľké komplexné výstavby bytových domov.",
    },
    {
      title: "Práce na exterieri, interieri",
      description:
        "Ako stavebná firma ponúkame rôzne služby. Primárna časť našich služieb je zameraná na kompletné zateplenie a rekonštrukciu rôznych budov. Ďalej sa venujeme aj rekonštrukcii vnútorných priestorov podľa vašich predstáv.",
    },
  ];

  const [startAnimation, setStartAnimation] = useState<boolean>(true);

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, []);

  const scrollHandler = () => {
    const aboutSection = document.getElementById("section-about");
    if (aboutSection != null) {
      const actualScrollPosition = window.scrollY + window.innerHeight;
      const startPoint = aboutSection.offsetTop + 300; //aboutSection.offsetHeight / 1.6;

      if (actualScrollPosition > startPoint) {
        setStartAnimation(true);
      } else if (actualScrollPosition <= startPoint) {
        setStartAnimation(false);
      }
    }
  };

  return (
    <div className={styles["section-about"]} id="section-about">
      <div
        className={`${styles.titles} ${
          startAnimation ? styles["title-fadein"] : ""
        }`}
        id="about-tittle"
      >
        <span></span>
        <p>O nás</p>
      </div>
      <div className={`${styles.about}`}>
        <div
          className={`${styles["about__wrapper"]} ${
            startAnimation ? styles["element_1_slide_effect"] : styles.hide
          }`}
        >
          <div className={`${styles["about__wrapper--title"]}`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="192"
              height="192"
              fill="#f03e3e"
              viewBox="0 0 256 256"
            >
              <rect width="256" height="256" fill="none"></rect>
              <path
                d="M128,128,67.2,82.4A8.1,8.1,0,0,1,64,76V40a8,8,0,0,1,8-8H184a8,8,0,0,1,8,8V75.6a8.1,8.1,0,0,1-3.2,6.4L128,128h0"
                fill="none"
                stroke="#f03e3e"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="16"
              ></path>
              <path
                d="M128,128,67.2,173.6A8.1,8.1,0,0,0,64,180v36a8,8,0,0,0,8,8H184a8,8,0,0,0,8-8V180.4a8.1,8.1,0,0,0-3.2-6.4L128,128h0"
                fill="none"
                stroke="#f03e3e"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="16"
              ></path>
              <line
                x1="74.7"
                y1="88"
                x2="180.9"
                y2="88"
                fill="none"
                stroke="#f03e3e"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="16"
              ></line>
              <line
                x1="128"
                y1="168"
                x2="128"
                y2="128"
                fill="none"
                stroke="#f03e3e"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="16"
              ></line>
            </svg>
            <p>Dlhoročné skúsenosti</p>
          </div>
          <p className={`${styles["about__wrapper--text"]}`}>
            {aboutUsItems[0].description}
          </p>
        </div>

        <div
          className={`${styles["about__wrapper"]} ${
            startAnimation ? styles["element_2_slide_effect"] : styles.hide
          }`}
        >
          <div className={`${styles["about__wrapper--title"]}`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="192"
              height="192"
              fill="#f03e3e"
              viewBox="0 0 256 256"
            >
              <rect width="256" height="256" fill="none"></rect>
              <line
                x1="16"
                y1="216"
                x2="240"
                y2="216"
                fill="none"
                stroke="#f03e3e"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="16"
              ></line>
              <path
                d="M144,216V40a8,8,0,0,0-8-8H40a8,8,0,0,0-8,8V216"
                fill="none"
                stroke="#f03e3e"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="16"
              ></path>
              <path
                d="M224,216V104a8,8,0,0,0-8-8H144"
                fill="none"
                stroke="#f03e3e"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="16"
              ></path>
              <line
                x1="64"
                y1="72"
                x2="96"
                y2="72"
                fill="none"
                stroke="#f03e3e"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="16"
              ></line>
              <line
                x1="80"
                y1="136"
                x2="112"
                y2="136"
                fill="none"
                stroke="#f03e3e"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="16"
              ></line>
              <line
                x1="64"
                y1="176"
                x2="96"
                y2="176"
                fill="none"
                stroke="#f03e3e"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="16"
              ></line>
              <line
                x1="176"
                y1="176"
                x2="192"
                y2="176"
                fill="none"
                stroke="#f03e3e"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="16"
              ></line>
              <line
                x1="176"
                y1="136"
                x2="192"
                y2="136"
                fill="none"
                stroke="#f03e3e"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="16"
              ></line>
            </svg>
            <p>Práca na veľkých projektoch</p>
          </div>
          <p className={`${styles["about__wrapper--text"]}`}>
            {aboutUsItems[1].description}
          </p>
        </div>

        <div
          className={`${styles["about__wrapper"]} ${
            startAnimation ? styles["element_3_slide_effect"] : styles.hide
          }`}
        >
          <div className={`${styles["about__wrapper--title"]}`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="192"
              height="192"
              fill="#f03e3e"
              viewBox="0 0 256 256"
            >
              <rect width="256" height="256" fill="none"></rect>
              <path
                d="M216,216V115.5a8.3,8.3,0,0,0-2.6-5.9l-80-72.7a8,8,0,0,0-10.8,0l-80,72.7a8.3,8.3,0,0,0-2.6,5.9V216"
                fill="none"
                stroke="#f03e3e"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="16"
              ></path>
              <line
                x1="16"
                y1="216"
                x2="240"
                y2="216"
                fill="none"
                stroke="#f03e3e"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="16"
              ></line>
              <path
                d="M152,216V160a8,8,0,0,0-8-8H112a8,8,0,0,0-8,8v56"
                fill="none"
                stroke="#f03e3e"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="16"
              ></path>
            </svg>
            <p>Práce na exterieri, interieri</p>
          </div>
          <p className={`${styles["about__wrapper--text"]}`}>
            {aboutUsItems[2].description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;

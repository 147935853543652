import React, { useEffect, useState } from "react";
import Menu from "./menu/menu";
import { InformationItem } from "../../models/informationItem";
import styles from "../introduction/introduction.module.scss";

const IntroductionPage = () => {
  const images: { src: string; alt: string }[] = [
    { src: require("../../assets/img/gino.jpg"), alt: "gino1" },
    { src: require("../../assets/img/gino2.jpg"), alt: "gino2" },
  ];

  const [selectedImgIndex, setSelectedImgIndex] = useState<number>(0);
  const [selectedGoalsIndex, setSelectedGoalsIndex] = useState<number>(0);
  const [nextImage, setNextImage] = useState<boolean>(true);
  const [scrollToPosition, setScrollToPosition] = useState<string>("");
  const goalsItems: InformationItem[] = [
    { title: "Zateplenie", description: "bytových a rodinných domov" },
    {
      title: "Rekonštrukcia, renovácia",
      description: "interiéru a exteriéru",
    },
  ];

  // useEffect(() => {
  //   window.addEventListener("beforeunload", alertUser);
  //   return () => {
  //     window.removeEventListener("beforeunload", alertUser);
  //   };
  // }, []);
  // const alertUser = (e: any) => {
  //   console.log(window.performance.navigation.type);
  // };

  useEffect(() => {
    // setTimeout(() => {
    //   console.log("first");
    //   setSelectedImgIndex(1);
    // }, 5000);
    const interval = setInterval(() => {
      let index = selectedImgIndex + 1;

      if (index === images.length) {
        index = 0;
      }
      setSelectedImgIndex(index);
      setSelectedGoalsIndex(index);
    }, 5000);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [selectedImgIndex]);

  const testClick = () => {
    setNextImage(!nextImage);
  };

  return (
    <section className={styles.introduction} id={"section-introduction"}>
      <Menu scrollToSection={scrollToPosition} />
      <div className={`${styles["introduction__background"]}`}>
        <ul>
          {images.map((image, i) => (
            <li
              key={i}
              className={`${styles.fade} ${
                selectedImgIndex === i ? styles["active-img"] : ""
              }`}
            >
              <img src={image.src} alt={image.alt} />
            </li>
          ))}
        </ul>
      </div>

      <div className={`${styles["introduction__content"]}`}>
        {goalsItems.map((item, index) => (
          <div
            key={index}
            className={`${styles["introduction__content--info"]} 
          ${
            selectedGoalsIndex === index
              ? styles["introduction__content--active"]
              : ""
          }`}
          >
            <p className={`${styles["info__title"]}`}>{item.title}</p>
            <p className={`${styles["info__description"]}`}>
              {item.description}
            </p>
          </div>
        ))}

        <div>
          <button
            className={`${styles["introduction__content--button"]}`}
            onClick={() => setScrollToPosition("section-projects")}
          >
            Naša práca
          </button>
          <button
            className={styles["introduction__content--button"]}
            onClick={() => setScrollToPosition("section-contact")}
          >
            Cenová ponuka
          </button>
        </div>
      </div>
    </section>
  );
};

export default IntroductionPage;

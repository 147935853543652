import React from "react";
import logo from "./logo.svg";
import "./App.css";
import IntroductionPage from "./components/introduction/introductionPage";
import AboutPage from "./components/about/aboutPage";
import Projects from "./components/projects/projects";
import Services from "./components/services/services";
import Contact from "./components/contact/contact";
import ActualProjects from "./components/actual-projects/actualProjects";
import { Cloudinary } from "@cloudinary/url-gen";

export const cloudinary = new Cloudinary({ cloud: { cloudName: "dyjprg5p9" } });

function App() {
  return (
    <div className="App">
      <IntroductionPage />
      <AboutPage />
      <Projects />
      <ActualProjects />
      <Services />
      <Contact />
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
    </div>
  );
}

export default App;
